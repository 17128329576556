<!--div style="height: 100vh">
    <header-->
      <div id="intro-section" class="view">  
        <video id="vid" class="video-intro" poster="https://clubb2b.com.br/images/main/background.jpg" 
          allowfullscreen playsinline oncanplay="this.play()" onloadedmetadata="this.muted=true" loop
          style="min-width: 100%; min-height: 100%;">
          <source src="https://clubb2b.com.br/videos/auto-parts.mp4" type="video/mp4">
        </video>
        <div class="mask rgba-gradient d-md-flex justify-content-center align-items-center overflow-auto">
          <div class="container px-md-3 px-sm-0">
            <!--Grid row-->
            <div class="row wow fadeIn">
              <!--Grid column-->
              <div class="col-md-12 mb-4 white-text text-center mb-0 mt-5 pt-md-5 pt-5 wow fadeIn">
                <h1 class="display-3 font-weight-bold sombra">
                  <a class="font-weight-bold black-text sombra">e</a>
                  <a class="font-weight-bold amber-text sombra">4</a>
                  <a class="font-weight-bold black-text sombra">Autos</a>
                </h1>
                <h1 class="font-weight-bold white-text sombra">
                  Um novo conceito de se fazer  
                  <a class="font-weight-bold amber-text sombra">Delivery</a>.
                </h1>
                <hr class="hr-light my-4 w-95">
                <h4 class="subtext-header mt-2 mb-4 text-justify">
                  <p>Nós da 
                    <a class="font-weight-bold text-success sombra">Club</a>
                    <a class="font-weight-bold text-warning sombra">B</a>
                    <a class="font-weight-bold text-primary sombra">2</a>
                    <a class="font-weight-bold sombra">B</a>
                    <a class="font-weight-bold text-success sombra"> Group</a>,
                    geramos novas oportunidades de negócios para você e para seus parceiros, ampliando sua rede de negócios
                    e criando novas parcerias, aumentando assim a competitividade de sua empresa no mercado de atuação da
                    mesma.
                    Não basta apenas vender, mas, poder ter e realizar uma 
                    <a class="font-weight-bold red-text sombra">Gestão Consciente</a>
                    do seu negócio, proporcionando a fidelização de seus clientes e o aumento de suas vendas.
                    </p>
                  <p class="font-weight-bold text-center mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">Em breve estaremos online para servi-los!</p>
                  <h1 *ngIf="innerWidth > 414" class="display-3 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE EM CONSTRUÇÃO
                  </h1>
                  <h1 *ngIf="innerWidth <= 414" class="display-5 font-weight-bold text-center amber-text mb-0 mt-3 pt-md-2 pt-2 pb-md-2 pb-2 sombra">
                    SITE EM CONSTRUÇÃO
                  </h1>
                  <div *ngIf="innerWidth > 414" class="timer">
                    <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                  <div *ngIf="innerWidth <= 414" class="timer-mobile">
                    <h4 class="font-weight-bold red-text sombra"> Contagem Regressiva </h4>
                    <span id="days" class="font-weight-bold teal-text sombra"> {{daysToDday}} </span>
                    <a class="font-weight-bold sombra">Dia(s)</a>
                    <span id="hours" class="font-weight-bold teal-text sombra"> {{hoursToDday}} </span>
                    <a class="font-weight-bold sombra">Hs</a> 
                    <span id="minutes" class="font-weight-bold teal-text sombra"> {{minutesToDday}} </span>
                    <a class="font-weight-bold sombra">Min</a>
                    <span id="seconds" class="font-weight-bold teal-text sombra"> {{secondsToDday}} </span>
                    <a class="font-weight-bold sombra">Seg</a>
                  </div>
                </h4> 
              </div>
            </div>
          </div>
        </div>
      </div>
    <!--/header>
  </div-->